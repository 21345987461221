import React, { useMemo } from 'react';

import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import APIErrorMessage from '../api-error-message/api-error-message';
import Button from '../button/button';
import Panel from '../panel/panel';
import InviteFormField from './invite-form-field';
import * as styles from './invite-person.module.less';

interface Contact {
  externalId?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
}

const initialValues: Contact = {
  externalId: '',
  emailAddress: '',
  firstName: '',
  lastName: '',
};
interface InvitePersonProps {
  variant: 'id-or-email' | 'name-and-email';
  handleSubmit: (values: Contact) => void;
  errorMessage: string | undefined;
  loading: boolean;
}

const InvitePerson: React.FC<InvitePersonProps> = ({ variant, handleSubmit, errorMessage, loading }) => {
  const { t } = useTranslation();
  const validationSchema = useMemo(() => {
    if (variant === 'id-or-email') {
      return Yup.object({
        externalId: Yup.string().required(t('id or email required')),
      });
    }
    return Yup.object({
      firstName: Yup.string().required(t('first name required')),
      lastName: Yup.string().required(t('last name required')),
      emailAddress: Yup.string().required(t('email required')),
    });
  }, [t, variant]);

  return (
    <Formik {...{ validationSchema, onSubmit: handleSubmit, initialValues }}>
      {({ isValid, dirty }) => {
        return (
          <Panel>
            <Form className={styles.addContact}>
              {variant === 'id-or-email' && (
                <InviteFormField
                  top
                  name="externalId"
                  label={t('id or email')}
                  placeholder={t('id or email placeholder')}
                />
              )}
              {variant === 'name-and-email' && (
                <>
                  <InviteFormField name="emailAddress" label={t('email address')} top />
                  <InviteFormField name="firstName" label={t('first name')} />
                  <InviteFormField name="lastName" label={t('last name')} />
                </>
              )}
              <APIErrorMessage error={errorMessage} />
              <div>
                <Button
                  disabled={!isValid || !dirty}
                  loading={loading}
                  spacing={{ margins: { md: 'top' } }}
                  size="md"
                  type="submit"
                >
                  {t('invite')}
                </Button>
              </div>
            </Form>
          </Panel>
        );
      }}
    </Formik>
  );
};

export default InvitePerson;
