import React from 'react';

import { TextField } from '../formik-fields/formik-fields';

interface InviteFormFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  top?: boolean;
}
const InviteFormField: React.FC<InviteFormFieldProps> = ({ name, label, placeholder, top }) => {
  return <TextField size="small" name={name} label={label + '*'} placeholder={placeholder ?? label} flushTop={top} />;
};

export default InviteFormField;
